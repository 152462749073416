* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  min-height: 100%;
}

body {
  margin: 0;
  background-color: #355393;
  color: white;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100%;
}

input,
select,
textarea {
  color: white;
}

p {
  margin: 0;
}

label.active {
  color: white;
  font-size: 16px;
}

div.Toastify__toast-body {
  color: #101010;
}
div.modal-content,
div.modal-footer {
  background-color: #e0e0e0 !important;
}
